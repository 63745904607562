import React, {useState} from 'react'
import './base.css'
import Container from './container'
import Navigation from './navigation'
import Footer from './footer'
import StickyHeader from './sticky-header'
import { Helmet } from "react-helmet"

export default function Layout(props) {

    return (


      <Container>
        <StickyHeader/>
        <Navigation />
        {props.children}
        <Footer />
      </Container>

    )
  }
