import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import styles from './section-header.module.css'

export default ({ data }) => (
  <div className={styles.sectionHeader}>
      <h1 className={styles.contentBlockTitle}>{data.title}</h1>
    </div>
)
