import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import ContentBlock from '../components/content-block'
import Container from '../components/container'
import Row from '../components/row'
import SectionHeader from '../components/section-header'


class BetaIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const contentfulContentBlocks = get(this, 'props.data.allContentfulContentBlock.edges')
    const fact1Data = contentfulContentBlocks[0].node
    const fact2Data = contentfulContentBlocks[1].node
    const fact3Data = contentfulContentBlocks[2].node

    const section1 = {
      title: "The Basics of Addiction"
    }


    return (
      <Layout location={this.props.location}>

          <Helmet title={siteTitle} />
          <SectionHeader data={section1} />
          <Row>
            <ContentBlock data={fact1Data} />
          </Row>
          <Row>
            <ContentBlock data={fact2Data} />
          </Row>
          <Row>
            <ContentBlock data={fact3Data} />
          </Row>
          <Row />


      </Layout>
    )
  }
}

export default BetaIndex

export const pageQuery = graphql`
  query WhyQuery {
    allContentfulContentBlock(sort: {order: ASC, fields: order} filter: {page: {eq:"/why"}}) {
    edges {
      node {
        backgroundImage {
          file {
            url
          }
        }
        blockImage {
          file {
            url
          }
        }
        contentText {
          contentText
        }
        contentful_id
        title
        link
        linkText
        order
        page
        imagePosition
      }
    }
  }

  allContentfulAsset(filter: {contentful_id: {eq: "7aMyX9u58lkIai4l271R0a"}}) {
  edges {
    node {
      file {
        url
      }
      contentful_id
    }
  }
}
  }
`
